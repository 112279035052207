import React from "react"
import { Helmet } from "react-helmet"
import NavigationBar from "../components/Navbar"
import loadable from "@loadable/component"
const Footer = loadable(() => import("../components/footer3"))
const SocialMedia = loadable(() => import("../components/social-media"))

const testimonials = [
  {
    text:
      "I was looking to brush up on my algorithm design understanding. After a casual search on the web, I came across the enjoyalgorithms. Immediately I was very impressed by the depth of information. Shubham has a knack for explaining complex things very easy to be understood by beginner or intermediate-level learners. He focuses on delivering quality instead of rushing towards completion. I recommend courses offered by Shubham to industry personnel who want to quickly review algorithm concepts and make a switch or start off with a good company.",
    author: "Rahul Jain",
    linkedin: "https://www.linkedin.com/in/rahul-j/",
  },
  {
    text:
      "EnjoyAlgorithms is my preferred pastime when I have spare moments, and I deeply appreciate the content they offer. The remarkable quality of their articles, whether delving into topics such as data structures and algorithms or system design, entices me to repeatedly return to their platform. I admire their practice of presenting multiple solutions to a given DSA problem, and I am grateful for the authors' willingness to kindly reply to my questions if I have any. I even have a special tab group called Enjoy Algorithms in my browser on my laptop and phone. This is just to give you an idea how I much I like their articles.",
    author: "Bogdan Hladiuc",
    linkedin: "https://www.linkedin.com/in/bogdanhladiuc/",
  },

  {
    text:
      "Switching my career to the machine learning domain after working for more than 20+ years in COTS products seems daunting, but I would like to thank Ravish for making it easier for me. While most online courses focus on the 'how' – using tools and getting results – they often lack the crucial 'why' – the fundamental understanding that empowers independent thinking. This is where EnjoyAlgorithms shines. The course starts with basic concepts and builds steadily, step by step. It has enabled me to implement ML projects and solve ML problems in my day-to-day work.",
    author: "Shrini Dhanaraj",
    linkedin: "https://www.linkedin.com/in/srini-dhanaraj/",
  },
  {
    text:
      "I joined this course because of the EnjoyAlgorithms site, which is clean and content-rich. My instructor, Shubham, is altogether in a different league. The good part is, that he will make you think rather than just teach. After a few weeks of the course itself, not only me but all my batchmates started to think methodologically, going with the incremental approach as Shubham does. We were always surprised by the number of solutions that Shubham talks about for each problem.",
    author: "Muthiah Prabhakaran",
    linkedin: "https://www.linkedin.com/in/m-pr/"
  },
  {
    text:
      "Enjoyalgorithms covers a lot of topics including DSA and system design and it's a complete goldmine for the techies. Every blog has very rich content and it helps you go from absolute beginner to highly advanced level. All the topics have been embellished with mathematics, computer science concepts, best practices, practice problems, and very deep ideas around the topic which makes it a one-stop solution for anyone who aspires to hit the summit in the computer science field. Thanks, Mr. Shubham Gautam for Enjoyalgorithms, it has changed my life!",
    author: "Ankur Katiyar",
    linkedin: "https://www.linkedin.com/in/ankur-katiyar-9a635651/",
  },

  {
    text:
      "I have had the opportunity to collaborate with Ravish on designing Enjoyalgorithm's ML content, and it has been an enlightening experience. His approach to explaining concepts and applications strikes a perfect balance for learners at all levels. The clarity with which he explains topics such as Gradient Descent, Kernels, Normalization, and Word2Vec is remarkable. Particularly, the 'Interview Questions' section in each blog proved immensely helpful during my data science interview preparations. For anyone delving into the realm of data science, Enjoyalgorithms is the ideal resource I would wholeheartedly recommend.",
    author: "Nimit Jain",
    linkedin: "https://www.linkedin.com/in/nimit-jain-831b24190/",
  },

  {
    text:
      "I got in touch with Shubham after reading his blogs. The main thing about his teaching is to simplify complex topics into a format which can be understood by people with a very basic understanding of the subject. His unique method is to elevate the intuition and thinking process when it comes to problem-solving with data structures. The approach he takes in explaining concepts like recursion, and algorithm analysis is beyond expectation. Following his approach, I feel confident in reading a text on algorithms with different insights. feeling lucky to have a mentor like him.",
    author: "Sajeesh KK",
    linkedin: "https://www.linkedin.com/in/sajeesh-kk-3462a96/",
  },

  {
    text:
      "I have had a chance to go through the courses at Enjoy Algorithms. Shubham has in-depth knowledge of the content he teaches and is extremely patient. The website content is world-class, especially the mathematical part, and it also has follow-on questions. The best part about the website is that it can be used by all levels of learners, i.e., from starters to advanced. The new look and feel of the website is excellent. I am highly impressed by the content and the team behind it!",
    author: "Anshul Gupta",
    linkedin: "https://www.linkedin.com/in/guptaanshul123/",
  },

  {
    text:
      "I recently had the pleasure of exploring Enjoy-Algorithms when I was preparing for my interviews. They are offering a wide range of courses on Data Structures and Algorithms, interview preparation, and OOPs concepts. EnjoyAlgorithms provides a one-stop solution for honing your computer science skills. The user interface of the platform is clean, intuitive, and well-organized, enabling smooth navigation and easy access to course materials.",
    author: "Ankit Kumawat",
    linkedin: "https://www.linkedin.com/in/ankit-kumawat-05443612a/",
  },

  {
    text:
      "I have been reading ML blogs from EnjoyAlgorithms every week, and I also had a chance to be the first one to attend their ML live sessions, which is a gold standard for anyone who wants to kickstart a career in ML and data science. The course starts with fundamentals and slowly progresses to advanced methodologies and hands-on projects critical for data scientist and ML engineer roles. Ravish, being an experienced mentor in this domain, I learned a lot from this course and would highly recommend it to anyone willing to learn ML and data science.",
    author: "Shashank Gupta",
    linkedin: "https://www.linkedin.com/in/shashank47/",
  },
  {
    text:
      "Ever since I visited the EnjoyAlgorithms website, Ravish’s expertly crafted blogs on machine learning and AI have become my go-to source for any related topic. His insightful writing simplifies complicated concepts, and with a blend of expertise and clarity, Ravish ensures that every blog is not just informative but also a pleasure to read. I especially appreciate the 'possible interview questions' section at the end of each blog, which makes me rethink and go through everything I have just learned.",
    author: "Abhijeet Anand",
    linkedin: "https://www.linkedin.com/in/abhijeetanand16/",
  },

  {
    text:
      "EnjoyAlgorithms is a platform for anyone enthusiastic about learning machine learning and data science. The course journey very well explains basic concepts to advanced methodologies and provides hands-on experience on projects where you can work on problem statements and apply your knowledge.",
    author: "Ayushi Agarwal",
    linkedin: "https://www.linkedin.com/in/ayushiagrawal1996/",
  },
  {
    text:
      "I am a big fan of Enjoy Algorithms. I find their content to be clear, concise, and well-explained. The examples are particularly helpful in making the concepts come alive. I always check Enjoy Algorithms first when I am trying to understand a new algorithm or data structure. It is my go-to resource for algorithm learning.",
    author: "Vikhyat Bhatnagar",
    linkedin: "https://www.linkedin.com/in/vikhyat-bhatnagar/",
  },
  {
    text:
      "Enjoy Algorithms is a comprehensive platform for those looking to make a career as a software engineer or ML engineer. The concepts are explained systematically and are easy to follow. Apart from covering theory, Enjoy Algorithms also provides hands-on projects for all their courses which are tremendously helpful and a great way to test what you have learned.",
    author: "Hemlata M Sharma",
    linkedin: "https://www.linkedin.com/in/sharmahemlata/",
  },

  {
    text:
      "While I was preparing for my next interview, I came across the enjoyalgorithms. It has great content, I used it to refresh my OOP concepts, also this site has great content for DSA Problem Solving and System Design, which helped me a lot during my interview preparation (even the most popular websites couldn't do true justice to these concepts). They explained the algorithm designs instead of just solving a bunch of problems. I also loved the Dynamic Programming content they have. Truly very good content around CS concepts. Thank you guys.",
    author: "Dinesh Gillala",
    linkedin: "https://www.linkedin.com/in/dinesh-gillala-6a7503215/",
  },

  {
    text:
      "EnjoyAlgorithms has been an invaluable resource for my growth as a programmer! From tackling advanced data structures like Fenwick and segment trees to diving into HLD concepts, system design, SOLID principles, and OOPS design patterns, the structured approach has been outstanding. Highly recommended for beginners seeking a solid foundation and experienced developers looking to level up their skills!",
    author: "Md Sameer",
    linkedin: "https://www.linkedin.com/in/sameer331/",
  },

  {
    text:
      "EnjoyAlgorithms delivers well-structured, simple yet crisp learning content to people who are looking forward to learning or refreshing their understanding of the concepts related to algorithms, problem-solving and system design. I relish their content, especially the Critical Ideas to Think section in each of their article. What more? EnjoyAlgorithms has a sibling EnjoyMathematics for the Math enthusiasts where we can also find Math topics that are essential for DSA.",
    author: "Yogesh P",
    linkedin: "https://www.linkedin.com/in/yogeshp93/",
  },

  {
    text:
      "I have always been fascinated with the field of machine learning and data science. Still, the biggest challenge always has been the overabundance of unstructured material on the topic, along with no proper guidance. This changed once I encountered the structured study plans given on EnjoyAlgorithms, and specifically, their high-quality data science blogs always add something unique to my knowledge. I would recommend anyone to at least explore the content themselves.",
    author: "Aviral agarwal",
    linkedin: "https://www.linkedin.com/in/aviral-agarwal-5b6970212",
  },
  {
    text:
      "EnjoyAlgorithms DSA course is a remarkable resource for anyone seeking to master algorithms and data structures. It offers a well-rounded learning experience with comprehensive content and outstanding problem-selection support. Whether you're a beginner looking to build a strong foundation or an intermediate learner aiming to enhance your problem-solving skills, this course shall serve the purpose. They are more towards pattern centric rather than the count of problems. I wholeheartedly recommend it to anyone looking to excel in algorithmic programming. Lastly, I loved the UI theme of the content.",
    author: "Pardha Saradhi Survi",
    linkedin: "https://www.linkedin.com/in/pardha-saradhi-survi-a406a21b1/",
  },

  {
    text:
      "I recently came across enjoyalgorithms while looking to enhance my understanding of algorithms, and I must say it exceeded my expectations. As an experienced software developer, I was seeking a reliable source to solidify my grasp on algorithms, and this website proved to be the perfect fit. The articles not only cover the fundamentals but also delve into advanced algorithms, catering to both beginners and those seeking a more comprehensive understanding. Moreover, the website's content is arranged logically, following a progressive learning path. This allowed me to navigate through different topics smoothly. Kudos to the team for creating such a valuable platform!",
    author: "Subhash Diwakar",
    linkedin: "https://www.linkedin.com/in/subhashdiwakar/",
  },

  {
    text:
      "I recently enjoyed using EnjoyAlgorithms to enhance my understanding of algorithms. This platform is designed to teach complex algorithms in an enjoyable and effective manner. The algorithms courses provided by EnjoyAlgorithms were top-notch! Each course covered various topics, from basic concepts to advanced algorithms. The content was presented well-structured and easy-to-follow format, making it suitable for learners of all levels.",
    author: "Ashish Gupta",
    linkedin: "https://www.linkedin.com/in/ashishgupta2015/",
  },

  {
    text:
      "EnjoyAlgorithms is a comprehensive platform for those looking to make a career as a fresher and want to clear FAANG interviews. The website provides well-structured, simple, and crisp content on algorithms, problem-solving, and system design. The content is delivered in a way that is easy to understand and follow, making it a valuable resource for anyone. Shubham is doing a great job of providing valuable content to the community. The website is regularly updated, and Shubham is always available to answer questions and provide feedback.",
    author: "Santosh Kumar",
    linkedin: "https://www.linkedin.com/in/santoshjee/",
  },

  {
    text:
      "I had the chance to attend Enjoyalgorithm's machine learning Live sessions. Even though I am a professional working as a data scientist, the session led by Ravish provided insights into many fundamental concepts of ML. This opportunity enabled me to delve into more granular details that went unnoticed earlier, helping me train better ML models. With Ravish's guidance, I got the opportunity to develop an end-to-end ML model from scratch, deepening my knowledge and adding richness to my resume.",
    author: "Harshit Agarwal",
    linkedin: "https://www.linkedin.com/in/harshit-agarwal-a340b3193/",
  },
  {
    text:
      "I wanted to learn Machine Learning, and EnjoyAlgorithms' blogs were a tremendous help. As a software engineer, I am strengthening my skills in ML by staying informed about recent industry trends. The way EnjoyAlgorithms explains complex ML concepts in their content is remarkably easy to understand. Ravish's blogs not only make ML less intimidating but also turn it into an exciting journey. He simplifies complex ideas and explains them with examples and real-life applications. If you're starting with Machine Learning and seeking a great mentor, Ravish is the right person.",
    author: "Alok Verma",
    linkedin: "https://www.linkedin.com/in/alok-verma001/",
  },

  {
    text:
      "I have been following EnjoyAlgorithms for a quiet long time, it is a true gem for data structures and algorithms enthusiasts. Its intuitive and well-organized interface ensures easy navigation on both mobile and desktop. Well-crafted explanations paired with engaging visuals create an enjoyable and highly effective learning experience. The platform's dedication to continuous updates reflects its genuine concern for users' progress, solidifying it as an exceptional resource for learners seeking to sharpen their coding skills. I highly recommend EnjoyAlgorithms to all passionate learners like me.",
    author: "JS JeevaSaravanan",
    linkedin: "https://www.linkedin.com/in/js-jeevasaravanan-a22951166/",
  },
  {
    text:
      "Certainly, it's the best in-depth course on algorithms and DSA. The explanations are very clear and concise. Shubham sir and the whole team are really great teachers and mentors. They always explain the topic in detail with different examples that help you understand and remember the concept forever. Their courses are excellent for programmers or developers who truly love solving problems and scaling their systems to the next level. I highly recommend this course to beginners and mid-range experienced problem solvers.",
    author: "Vijay Shelke",
    linkedin: "https://www.linkedin.com/in/vijaysshelke/",
  },

  {
    text:
      "I must say that my experience with the enjoyalgorithms website was nothing short of exceptional. The website offers a wealth of resources related to DSA, making it a valuable destination for anyone who wants to enhance understanding in this area. Whether you're a beginner looking to grasp the fundamentals or an experienced professional seeking advanced concepts, the website caters to all levels of expertise. The tutorials are well-written, concise, and accompanied by relevant examples. Lastly, the responsiveness of the website on different devices deserves praise.",
    author: "Tejasvi Malladi",
    linkedin: "https://www.linkedin.com/in/tmajestic/",
  },
  {
    text:
      "I've been a learner on the site for the past few months, and I've been really impressed with the quality of the content. The explanations are clear and concise. I've learned a lot from the courses and am confident that my skills have improved significantly. I particularly appreciate the way that EnjoyAlgorithms focuses on real-world applications. This has helped me see how algorithms can be used to solve problems in various industries. I would highly recommend EnjoyAlgorithms to anyone interested in learning about algorithms.",
    author: "Bhavya Kripalani",
    linkedin: "https://www.linkedin.com/in/bhavyakripalani/",
  },
  {
    text:
      "EnjoyAlgorithms is awesome! Before starting the course I was confused as to where to start DSA. But Shubham helped me a lot to take interest in DSA and explain maths and DSA with basics, now I am enjoying data structures and algorithms. This course helps the learners who have not known mathematics for a long time.",
    author: "Anup Kumar Shukla",
    linkedin:
      "https://www.linkedin.com/in/anupkumar-shukla-%E0%A4%85%E0%A4%A8%E0%A5%81%E0%A4%AA%E0%A4%95%E0%A5%81%E0%A4%AE%E0%A4%BE%E0%A4%B0-%E0%A4%B6%E0%A5%81%E0%A4%95%E0%A5%8D%E0%A4%B2%E0%A4%BE-4a054234/",
  },

  {
    text:
      "This blog's content is truly impressive, especially when discussing DSA and Machine Learning. The articles are exceptionally well-structured, making complex topics much more accessible and easier to grasp. The author's ability to simplify intricate concepts stood out and greatly contributed to my understanding.",
    author: "Henry Tirla",
    linkedin: "https://www.linkedin.com/in/henry-tirla/",
  },

  {
    text:
      "I am a Lead Machine Learning Architect working at Amex and happened to come across the EnjoyAlgorithms site and instantly fell in love with it. The quality and depth of contents within this site speak for itself and are an attestation of the time and thought your team has put into making this site useful. I already have started recommending my team and my peers to join EnjoyAlgorithms.",
    author: "Niladri Bhattacharjya",
  },
  {
    text:
      "I really liked the explanation of each approach from brute force to an optimization to the fully optimized solution. This really helped me understand what each optimization is clearly doing and how we remove unnecessary computations that are repeated to get the final answer.",
    author: "P Srikanth Kini",
  },
  {
    text:
      "I really appreciate the content and materials related to EnjoyAlgorithms. There are a lot of good materials especially deep explanations theoretically and practically for DSA and Algorithms. All the best!",
    author: "Mihajlo Galicki",
    linkedin: "https://www.linkedin.com/in/mihajlo-galicki/",
  },
  {
    text:
      "I came across EnjoyAlgorithms in recent times and went through the website. I was really happy to see everything required for a tech interview in one place. Great work. Kudos to the entire team.",
    author: "Gaurav Golchha",
  },

  {
    text:
      "I have been following articles from enjoyalgorithms. I see the quality, explanation and research at the highest level. Better than many paid sites. Please keep on doing this.",
    author: "Gautam Shukla",
  },
  {
    text:
      "I really appreciate your efforts in keeping concise and great blogs in enjoyalgorithms. After Wikipedia, I find your blog very useful.",
    author: "Spa Philo",
  },
]

export default function Testimonial() {
  return (
    <div className="mb-6">
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta charSet="utf-8" />
        <title> EnjoyAlgorithms Reviews</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="description"
          content="Discover testimonials and reviews from tech professionals about EnjoyAlgorithms courses, content, and learning methods. Explore how the platform has helped them sharpen their computer science skills."
        />
      </Helmet>
      <div className="sm:sticky top-0 z-50">
        <NavigationBar />
      </div>
      <div className="mt-6 container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8">
        <h1 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl text-gray-900 text-center">
          What Tech Professionals Say about EnjoyAlgorithms?
        </h1>
        <div className="grid max-w-lg mx-auto my-2 gap-6 xl:gap-8 xl:grid-cols-2 sm:max-w-2xl md:max-w-3xl lg:max-w-full mt-8 mb-6">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="py-3 px-4 shadow-md rounded-2xl md:pl-6 md:pr-4"
            >
              <blockquote className="mt-4">
                <div className="relative text-sm h-auto 2xl:text-base leading-7 text-gray-600">
                  <svg
                    className="absolute top-0 left-0 h-7 w-7 text-new-purple"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="ml-10 tracking-wider relative">
                    {testimonial.text}
                  </p>
                </div>
                <footer className="mt-4 ml-10">
                  <div className="flex tracking-wider text-sm 2xl:text-base leading-6 text-gray-600">
                    {testimonial.author}
                    {testimonial.linkedin && (
                      <div className="ml-2 -mt-1">
                        <a
                          href={testimonial.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <svg
                            x="0px"
                            y="0px"
                            width="50"
                            height="50"
                            viewBox="0 0 48 48"
                            className="w-7 h-7"
                          >
                            <path
                              fill="#0288D1"
                              d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                            ></path>
                            <path
                              fill="#FFF"
                              d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                            ></path>
                          </svg>
                        </a>
                      </div>
                    )}
                  </div>
                </footer>
              </blockquote>
            </div>
          ))}
        </div>

        <SocialMedia />
        <div className="mt-6">
          <Footer />
        </div>
      </div>
    </div>
  )
}
